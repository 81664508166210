import { LoLang } from "@maven-rest/common/common.types";
import { getBrowserDefaultLanguage } from "@maven-rest/states";
import { LOCALE_LANG_KEY } from "@maven-surface/layouts/Header";
import { MvnLanguage } from "@maven-msg/lib/Message";

export const getLanguage = (): LoLang => {
  const localStorageLanguage = localStorage.getItem(LOCALE_LANG_KEY) as LoLang;

  return localStorageLanguage || getBrowserDefaultLanguage();
};

export const convertLolangToMvnLanguage = (lolang: LoLang): MvnLanguage => {
  switch (lolang) {
    case LoLang.KO:
      return MvnLanguage.KO;
    default:
      return MvnLanguage.EN;
  }
};

export const allowOnlyNumber = (val: string): string => {
  return val.replace(/[^0-9]/g, "");
};
