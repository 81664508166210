import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_USER_LANG: {
  USER_PASSWORD_RULE: MvnCodeFE;
  USER_NOT_FOUND_BY_EMAIL: MvnCodeFE;
  USER_ADDED_ALREADY: MvnCodeFE;
  USER_ADDED: MvnCodeFE;
  USER_PRIVILEGES_SAVE_SUCC: MvnCodeFE;
  USER_PRIVILEGES_SAVE_FAIL: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_AGREE_ALL: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_REQUIRED: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_OPTIONAL: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_LEGAL: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_PERSONAL_DATA: MvnCodeFE;
  USER_SIGNUP_MARKETING_LEGAL: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_LEGAL_PREFIX: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_LEGAL_SUFFIX: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_PERSONAL_PREFIX: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_PERSONAL_SUFFIX: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_MARKETING_PREFIX: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_MARKETING_SUFFIX: MvnCodeFE;
  USER_SIGNUP_AGREEMENT_AGE: MvnCodeFE;
  USER_SIGNUP_SUCC: MvnCodeFE;
  USER_SIGNUP_FAIL: MvnCodeFE;
  USER_SIGNIN_FAIL: MvnCodeFE;
  USER_FORGOT_PASSWORD_TITLE: MvnCodeFE;
  USER_FORGOT_PASSWORD_EMAIL_LABEL: MvnCodeFE;
  USER_FORGOT_PASSWORD_EMAIL_PLACEHOLDER: MvnCodeFE;
  USER_FORGOT_PASSWORD_CODE_LABEL: MvnCodeFE;
  USER_FORGOT_PASSWORD_CODE_PLACEHOLDER: MvnCodeFE;
  USER_CHANGE_PASSWORD_TITLE: MvnCodeFE;
  USER_CHANGE_PASSWORD_PLACEHOLDER: MvnCodeFE;
  USER_CHANGE_PASSWORD_CONFIRM_PLACEHOLDER: MvnCodeFE;
  USER_CHANGE_PASSWORD_CHANGE_SUCC: MvnCodeFE;
  USER_CHANGE_PASSWORD_CHANGE_FAIL: MvnCodeFE;
  USER_REDIRECT_CHANGE_PASSWORD_LOADING: MvnCodeFE;
  USER_UPDATE_PROFILE_SUCC: MvnCodeFE;
  USER_UPDATE_PROFILE_FAIL: MvnCodeFE;
  USER_TOKEN_REFRESH_SUCC: MvnCodeFE;
  USER_TOKEN_REFRESH_FAIL: MvnCodeFE;
  USER_TOKEN_EXPIRY_ALERT: MvnCodeFE;
  USER_TOKEN_EXPIRY_SESSION: MvnCodeFE;
  USER_TOKEN_EXTEND_CONFIRM: MvnCodeFE;
  USER_TOKEN_EXTEND_BTN_DESC: MvnCodeFE;
  USER_PROFILE_EMAIL_LABEL: MvnCodeFE;
  USER_PROFILE_NAME_LABEL: MvnCodeFE;
  USER_PROFILE_PHONE_LABEL: MvnCodeFE;
  USER_PROFILE_CURRENT_PASSWORD_LABEL: MvnCodeFE;
  USER_PROFILE_NEW_PASSWORD_LABEL: MvnCodeFE;
  USER_PROFILE_NEW_PASSWORD_CONFIRM_LABEL: MvnCodeFE;
  USER_PROFILE_NEW_PASSWORD_CONFIRM_DESCRIPTION_INVALID: MvnCodeFE;
  USER_PROFILE_MARKETING_LABEL: MvnCodeFE;
  USER_PROFILE_PHONE_PLACEHOLDER: MvnCodeFE;
} = {
  USER_PASSWORD_RULE: {
    ko: "비밀번호는 최소 8글자 이상, 1개 이상의 숫자, 영문, 특수문자를 포함해야 합니다.",
    en: "Password must contain at least 8 characters, 1 number, 1 English and 1 symbol.",
  },
  USER_NOT_FOUND_BY_EMAIL: {
    ko: "해당하는 사용자를 찾을 수 없습니다.",
    en: "No user found with the specified email address.",
  },
  USER_ADDED_ALREADY: {
    ko: "사용자가 이미 추가되어 있습니다.",
    en: "User has added already.",
  },
  USER_ADDED: {
    ko: "사용자가 추가되었습니다.",
    en: "User has been added successfully.",
  },
  USER_PRIVILEGES_SAVE_SUCC: {
    ko: "사용자 권한을 저장하였습니다.",
    en: "User privileges have been saved successfully.",
  },
  USER_PRIVILEGES_SAVE_FAIL: {
    ko: "사용자 권한 저장에 실패했습니다. ({message})",
    en: "The user privilege saving request has been failed. ({message})",
  },
  USER_SIGNUP_AGREEMENT_AGREE_ALL: {
    ko: "모두 동의합니다.",
    en: "I consent to all of the following.",
  },
  USER_SIGNUP_AGREEMENT_REQUIRED: {
    ko: "(필수)",
    en: "(Required)",
  },
  USER_SIGNUP_AGREEMENT_OPTIONAL: {
    ko: "(선택)",
    en: "(Optional)",
  },
  USER_SIGNUP_AGREEMENT_LEGAL: {
    ko: "Maven Clinical Cloud 이용약관",
    en: "Maven Clinical Cloud Terms of Service",
  },
  USER_SIGNUP_AGREEMENT_PERSONAL_DATA: {
    ko: "개인정보 수집 및 이용",
    en: "Collection and Use of Personal Information",
  },
  USER_SIGNUP_MARKETING_LEGAL: {
    ko: "마케팅 정보 수신",
    en: "Receive Marketing Information",
  },
  USER_SIGNUP_AGREEMENT_LEGAL_PREFIX: {
    ko: "",
    en: "I agree to",
  },
  USER_SIGNUP_AGREEMENT_LEGAL_SUFFIX: {
    ko: "에 동의합니다.",
    en: ".",
  },
  USER_SIGNUP_AGREEMENT_PERSONAL_PREFIX: {
    ko: "",
    en: "I consent to",
  },
  USER_SIGNUP_AGREEMENT_PERSONAL_SUFFIX: {
    ko: "에 동의합니다.",
    en: ".",
  },
  USER_SIGNUP_AGREEMENT_MARKETING_PREFIX: {
    ko: "",
    en: "I consent to",
  },
  USER_SIGNUP_AGREEMENT_MARKETING_SUFFIX: {
    ko: "에 동의합니다.",
    en: ".",
  },
  USER_SIGNUP_AGREEMENT_AGE: {
    ko: "만 14세 이상입니다.",
    en: "I confirm that I am 14 years old or older.",
  },
  USER_SIGNUP_SUCC: {
    ko: "회원가입이 완료되었습니다.",
    en: "User registration has been completed successfully.",
  },
  USER_SIGNUP_FAIL: {
    ko: "회원가입에 실패하였습니다. ({message})",
    en: "User registration request has been failed. ({message})",
  },
  USER_SIGNIN_FAIL: {
    ko: "로그인에 실패하였습니다. ({message})",
    en: "User sining-in request has been failed. ({message})",
  },
  USER_FORGOT_PASSWORD_TITLE: {
    ko: "Forgot Password",
    en: "Forgot Password",
  },
  USER_FORGOT_PASSWORD_EMAIL_LABEL: {
    ko: "이메일",
    en: "Email",
  },
  USER_FORGOT_PASSWORD_EMAIL_PLACEHOLDER: {
    ko: "비밀번호를 초기화 할 계정의 이메일 주소를 입력해 주세요.",
    en: "Please enter your email address to reset your password.",
  },
  USER_FORGOT_PASSWORD_CODE_LABEL: {
    ko: "인증코드",
    en: "Verification Code",
  },
  USER_FORGOT_PASSWORD_CODE_PLACEHOLDER: {
    ko: "인증코드를 입력해 주세요.",
    en: "Please enter the verification code.",
  },
  USER_CHANGE_PASSWORD_TITLE: {
    ko: "Change Password",
    en: "Change Password",
  },
  USER_CHANGE_PASSWORD_PLACEHOLDER: {
    ko: "새 비밀번호를 입력해 주세요.",
    en: "Please enter a new password.",
  },
  USER_CHANGE_PASSWORD_CONFIRM_PLACEHOLDER: {
    ko: "새 비밀번호를 한번 더 입력해 주세요.",
    en: "Please confirm a new password.",
  },
  USER_CHANGE_PASSWORD_CHANGE_SUCC: {
    ko: "비밀번호를 변경하였습니다. 로그인해 주세요.",
    en: "Password has been changed successfully. Please log in.",
  },
  USER_CHANGE_PASSWORD_CHANGE_FAIL: {
    ko: "비밀번호 변경에 실패하였습니다. ({message})",
    en: "The password changing request has been failed. ({message})",
  },
  USER_REDIRECT_CHANGE_PASSWORD_LOADING: {
    ko: "비밀번호 재설정 페이지로 이동 중입니다.",
    en: "You are being redirected to the password reset page.",
  },
  USER_UPDATE_PROFILE_SUCC: {
    ko: "사용자 정보를 변경하였습니다.",
    en: "User profile has been updated successfully.",
  },
  USER_UPDATE_PROFILE_FAIL: {
    ko: "사용자 정보 변경에 실패하였습니다. ({message})",
    en: "The user profile updating request has been failed. ({message})",
  },
  USER_TOKEN_REFRESH_SUCC: {
    ko: "세션 유효시간이 연장되었습니다.",
    en: "Your login session has extended.",
  },
  USER_TOKEN_REFRESH_FAIL: {
    ko: "세션 유효시간 연장에 실패하였습니다.",
    en: "Failed to extend login session. ({error})",
  },
  USER_TOKEN_EXPIRY_ALERT: {
    ko: "{minutes}분 {seconds}초 후에 로그인 세션이 만료됩니다.",
    en: "Your login session will expire in {minutes}min {seconds}s.",
  },
  USER_TOKEN_EXTEND_CONFIRM: {
    ko: "연장하시겠습니까?",
    en: "Will you extend your login session?",
  },
  USER_TOKEN_EXTEND_BTN_DESC: {
    ko: "로그인 시간 연장하기",
    en: "Extend login session",
  },
  USER_TOKEN_EXPIRY_SESSION: {
    ko: "세션 만료",
    en: "Session Expired",
  },
  USER_PROFILE_EMAIL_LABEL: {
    ko: "이메일",
    en: "Email",
  },
  USER_PROFILE_NAME_LABEL: {
    ko: "이름",
    en: "Name",
  },
  USER_PROFILE_PHONE_LABEL: {
    ko: "연락처",
    en: "Phone",
  },
  USER_PROFILE_CURRENT_PASSWORD_LABEL: {
    ko: "현재 비밀번호",
    en: "Current Password",
  },
  USER_PROFILE_NEW_PASSWORD_LABEL: {
    ko: "신규 비밀번호",
    en: "New Password",
  },
  USER_PROFILE_NEW_PASSWORD_CONFIRM_LABEL: {
    ko: "신규 비밀번호 확인",
    en: "New Password Confirm",
  },
  USER_PROFILE_NEW_PASSWORD_CONFIRM_DESCRIPTION_INVALID: {
    ko: "신규 비밀번호가 일치하지 않습니다.",
    en: "The new password does not match.",
  },
  USER_PROFILE_MARKETING_LABEL: {
    ko: "마케팅 정보 수신 동의 여부",
    en: "Receive Marketing Information",
  },
  USER_PROFILE_PHONE_PLACEHOLDER: {
    ko: "하이픈(-)을 제외하고 입력해 주세요.",
    en: "Please exclude hyphens(-).",
  },
};

MvnCodeInitiate(AUTH_USER_LANG);

export const AUTH_USER_AUDIT_TRAIL_LANG: {
  AUTH_USER_AUDIT_TRAIL_PASSWORD: MvnCodeFE;
  AUTH_USER_AUDIT_TRAIL_MARKETING_AGREE: MvnCodeFE;
  AUTH_USER_AUDIT_TRAIL_MARKETING_DISAGREE: MvnCodeFE;
} = {
  AUTH_USER_AUDIT_TRAIL_PASSWORD: {
    ko: "Password changed",
    en: "Password changed",
  },
  AUTH_USER_AUDIT_TRAIL_MARKETING_AGREE: {
    ko: "Accepted",
    en: "Accepted",
  },
  AUTH_USER_AUDIT_TRAIL_MARKETING_DISAGREE: {
    ko: "Rejected",
    en: "Rejected",
  },
};

MvnCodeInitiate(AUTH_USER_AUDIT_TRAIL_LANG);
