import { getLanguage } from "@lib/cdms-lib/lib";
import { classNames } from "@maven-helper/functions/Misc.function";
import { LoLang, MavenService, Nullable } from "@maven-rest/common/common.types";
import { AuthTokenFlax } from "@maven-rest/sso/AuthToken.flax";
import { UserDeviceFlax } from "@maven-rest/sso/UserDevice.flax";
import { UserVerificationCodeFlax } from "@maven-rest/sso/UserVerificationCode.flax";
import { DEVICE_KEY, getCookie, setCookie } from "@maven-rest/states";
import { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Route, RouteProps, Switch, useLocation } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { useRequest } from "@coralblack/flax";
import { orchestraMsaApi } from "maven-lib/dist/states/apis/orchestra";
import { userApi } from "maven-lib/dist/states/apis/user";
import { sessionSlice } from "maven-lib/dist/states/reducers/session";
import { CrButton } from "@maven-surface/components/base/CrButton";
import { DuplicateLoginDialog, DuplicateLoginDialogMessageProps } from "@maven-surface/pages/auth/layout/dialogs/DuplicateLoginDialog";
import {
  InitEmailVerifyPasswordDialog,
  InitEmailVerifyPasswordDialogMessageProps,
} from "@maven-surface/pages/auth/layout/dialogs/InitEmailVerifyPasswordDialog";
import { InitPasswordDialog, InitPasswordDialogMessageProps } from "@maven-surface/pages/auth/layout/dialogs/InitPasswordDialog";
import { UpdatePasswordDialog, UpdatePasswordDialogMessageProps } from "@maven-surface/pages/auth/layout/dialogs/UpdatePasswordDialog";
import { AUTH_TOKEN_CODE, SSO_AUTH_TOKEN_CODE, SSO_USER_DEVICE_CODE } from "@maven-msg/code";
import { AUTH_TOKEN_LANG, AUTH_USER_VERIFICATION_CODE_LANG, COMMON_LANG, SSO_USER_VERIFICATION_CODE_LANG } from "@maven-msg/lang";
import { MvnMessage } from "@maven-msg/lib/Message";
import { v4 as uuidv4 } from "uuid";

interface AuthLayoutProps extends RouteProps {
  service: MavenService;
  messages: {
    code: {
      initialPasswordNotSet: string;
      passwordNotConfigured: string;
      passwordChangeCycleExceed: string;
      previousAuthTokenAlive: string;
    };
  };
  initEmailVerifyPasswordDialog: {
    messages: InitEmailVerifyPasswordDialogMessageProps;
  };
  initPasswordDialog: {
    messages: InitPasswordDialogMessageProps;
  };
  updatePasswordDialog: {
    messages: UpdatePasswordDialogMessageProps;
  };
  duplicateLoginDialog: {
    messages: DuplicateLoginDialogMessageProps;
  };
}

const queue: Array<string> = [];

export const IS_NEED_NOTIFY_LAST_LOGINTIME = "isNeedNotifyLastLoginTime";

export function AuthLayoutInner(props: AuthLayoutProps) {
  // TODO 하위호환을 위한 optional 처리, 추후 문제가 없을 경우, messages에 대해 필수값으로 수정 필요
  // const { service, messages, initPasswordDialog, initEmailVerifyPasswordDialog, updatePasswordDialog, duplicateLoginDialog } = props;
  const { service, messages, initEmailVerifyPasswordDialog } = props;
  const initPasswordDialog = props?.initPasswordDialog;
  const updatePasswordDialog = props?.updatePasswordDialog;
  const duplicateLoginDialog = props?.duplicateLoginDialog;

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const redirectTo = (state as any)?.from?.pathname;

  const [emailVal, setEmailVal] = useState("");
  const [passwordVal, setPasswordVal] = useState("");
  const [initPasswordDialogVisibility, setInitPasswordDialogVisibility] = useState(false);
  const [initEmailVerifyPasswordDialogVisibility, setInitEmailVerifyPasswordDialogVisibility] = useState(false);
  const [updatePasswordDialogVisibility, setUpdatePasswordDialogVisibility] = useState(false);
  const [duplicateLoginDialogVisibility, setDuplicateLoginDialogVisibility] = useState(false);
  const [isPasswordConfiguredState, setIsPasswordConfiguredState] = useState("");
  const [verificationCodeUuid, setVerificationCodeUuid] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const verifyBtnRef = useRef<HTMLButtonElement>(null);
  const lang = getLanguage();

  const { request: createMyDeviceRequest, response: createMyDeviceResponse } = useRequest(
    {
      ...UserDeviceFlax.createMyDevice({
        uuid: verificationCodeUuid,
        code: verificationCode,
        token: getCookie(DEVICE_KEY),
      }),
    },
    {
      success: (resp) => {
        const { token, expiryAt } = resp.deviceToken;

        if (token) {
          setCookie(DEVICE_KEY, token, new Date(expiryAt));
        }

        emailVal &&
          loginRequest({ data: { email: emailVal, password: passwordVal, service, checkPreviousAuthToken: true, deviceToken: token } });
      },
      error: (error) => MvnMessage(AUTH_USER_VERIFICATION_CODE_LANG.USER_VERIFICATION_CODE_EMAIL_VERIFY_FAIL, { message: error }),
    }
  );

  const { request: verifyUserVerificationCodeRequest, response: verifyUserVerificationCodeResponse } = useRequest(
    {
      ...UserVerificationCodeFlax.verifyUserVerificationCode({
        uuid: verificationCodeUuid,
        code: verificationCode,
      }),
    },
    {
      success: (resp) => {
        createMyDeviceRequest();
      },
      error: (error) => MvnMessage(AUTH_USER_VERIFICATION_CODE_LANG.USER_VERIFICATION_CODE_EMAIL_VERIFY_FAIL, { message: error }),
    }
  );

  const { request: sendVerificationCodeForDeviceRegistrationRequest, response: sendVerificationCodeForDeviceRegistrationResponse } =
    useRequest(
      {
        ...UserVerificationCodeFlax.createUserVerificationCodeForDeviceRegistration({ email: emailVal }),
      },
      {
        success: (resp) => {
          setVerificationCodeUuid(resp.userVerificationCode.uuid);
          setInitPasswordDialogVisibility(false);
          setInitEmailVerifyPasswordDialogVisibility(false);

          return MvnMessage(SSO_USER_VERIFICATION_CODE_LANG.SSO_USER_VERIFICATION_CODE_USER_DEVICE_EMAIL_SENT);
        },
        error: (error) => {
          return { title: "Send Mail Error", message: error?.message || "An error has occurred." };
        },
      }
    );

  const { request: loginRequest, response: loginResponse } = useRequest(
    {
      ...AuthTokenFlax.createAuthTokenForTest({
        email: "",
        password: "",
        service: MavenService.MavenCDMS,
      }),
    },
    {
      success: (resp) => {
        sessionStorage.setItem(IS_NEED_NOTIFY_LAST_LOGINTIME, "true");
        dispatch(sessionSlice.actions.signIn({ ...(resp as any), redirectTo }));
      },
      error: (error) => {
        if (error?.code === messages.code.initialPasswordNotSet) {
          setInitPasswordDialogVisibility(true);
        } else if (error?.code === messages.code.passwordNotConfigured) {
          setInitEmailVerifyPasswordDialogVisibility(true);
        } else if (error?.code === messages.code.passwordChangeCycleExceed) {
          setUpdatePasswordDialogVisibility(true);
        } else if (error?.code === messages.code.previousAuthTokenAlive) {
          setDuplicateLoginDialogVisibility(true);
        } else if (error?.code === SSO_USER_DEVICE_CODE.SSO_USER_DEVICE_CODE_USER_DEVICE_TOKEN_INVALID.code) {
          sendVerificationCodeForDeviceRegistrationRequest();

          return;
        }

        const message = ((code: string) => {
          switch (code) {
            case SSO_AUTH_TOKEN_CODE.SSO_AUTH_TOKEN_CREATE_INVALID_USER_LOCKED_BY_INVALID_PASSWORD.code:
              return MvnMessage(SSO_AUTH_TOKEN_CODE.SSO_AUTH_TOKEN_CREATE_INVALID_USER_LOCKED_BY_INVALID_PASSWORD, error.data.binds);
            default:
              return error?.message || "An error has occurred.";
          }
        })(error?.code);

        return { title: "Login Error", message };
      },
    }
  );

  const cache = (key: string, val?: boolean): Nullable<boolean> => {
    const k = `auth:cfg:${key}`;

    if (val !== undefined) {
      localStorage.setItem(k, JSON.stringify({ val, exp: new Date().getTime() + (val === true ? 3600 * 24 * 30 : 5) * 1000 }));
    }

    const cached = localStorage.getItem(k);

    if (!cached) return null;

    try {
      const { val, exp } = JSON.parse(cached);

      if (new Date().getTime() > exp) return null;

      return val;
    } catch (e) {
      return null;
    }
  };

  const { request: checkIsPasswordConfiguredReq, cancel: cancelCheckIsPasswordConfigured } = useRequest(
    { ...userApi.checkIsPasswordConfigured, cacheMaxAge: 3600 * 24 },
    {
      success: (resp) => {
        cache(resp.email, resp.isPasswordConfigured);

        const q = queue.pop();

        if (q && typeof cache(q) !== "boolean") {
          const res = checkIsPasswordConfigured(q);

          if (res === false) queue.push(q);
        }

        setIsPasswordConfiguredState(uuidv4());
      },
    }
  );

  const { request: wakenMss } = useRequest({ ...orchestraMsaApi.waken });

  const checkIsPasswordConfigured = (email: string) => {
    const cached = cache(email);

    if (typeof cached === "boolean") return cached;

    checkIsPasswordConfiguredReq({ data: { email } });

    return true;
  };

  return (
    <Switch>
      <Route
        exact={true}
        path="/auth"
        render={() => (
          <div className="app-layout">
            <div className="app-fullpage">
              <div className="app-auth">
                <div className="app-auth-wrapper">
                  <div className="app-auth-side">
                    <div className="app-auth-side-wrapper">
                      <strong>{process.env.REACT_APP_NAME}</strong>
                      <p>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </p>
                    </div>
                  </div>
                  <div className="app-auth-content">
                    <div className="app-auth-content-wrapper">
                      <h1 className={classNames("logo", service.toLocaleLowerCase())}>{process.env.REACT_APP_NAME}</h1>
                      <div className="header">
                        <h2 className="title">Sign in</h2>
                        <p className="help">
                          Any problem?{" "}
                          <a href="https://www.jnpmedi.com" target="_blank" rel="noopener noreferrer">
                            Ask us anything!
                          </a>
                        </p>
                      </div>
                      <div className="form">
                        <fieldset>
                          <legend>Sign in</legend>
                          {!verificationCodeUuid && (
                            <>
                              <label htmlFor="email">
                                <input
                                  ref={emailRef}
                                  defaultValue={emailVal}
                                  id="email"
                                  placeholder="Email"
                                  className="email"
                                  type="email"
                                  onBlur={() => {
                                    wakenMss();
                                  }}
                                  onChange={(e) => {
                                    setEmailVal(e.target.value);

                                    if (isEmail(e.target.value)) {
                                      cancelCheckIsPasswordConfigured();

                                      queue.push(String(e.target.value || "").toLowerCase());
                                      checkIsPasswordConfigured(String(e.target.value || "").toLowerCase());
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    const deviceToken = getCookie(DEVICE_KEY);

                                    e.key === "Enter" &&
                                      emailVal &&
                                      !checkIsPasswordConfigured(emailVal) &&
                                      loginRequest({
                                        data: {
                                          email: emailRef,
                                          password: passwordRef,
                                          service,
                                          checkPreviousAuthToken: true,
                                          deviceToken,
                                        },
                                      });
                                  }}
                                />
                              </label>
                              <Fragment key={isPasswordConfiguredState}>
                                {cache(emailVal) !== false && (
                                  <label htmlFor="password">
                                    <input
                                      ref={passwordRef}
                                      id="password"
                                      placeholder="Password"
                                      className="password"
                                      type="password"
                                      onBlur={() => {
                                        wakenMss();
                                      }}
                                      onChange={(e) => {
                                        setPasswordVal(e.target.value);
                                      }}
                                      onKeyDown={(e) => {
                                        const deviceToken = getCookie(DEVICE_KEY);

                                        e.key === "Enter" &&
                                          emailVal &&
                                          passwordVal &&
                                          loginRequest({
                                            data: {
                                              email: emailRef,
                                              password: passwordRef,
                                              service,
                                              checkPreviousAuthToken: true,
                                              deviceToken,
                                            },
                                          });
                                      }}
                                    />
                                  </label>
                                )}
                              </Fragment>
                            </>
                          )}
                          {verificationCodeUuid && (
                            <label htmlFor="code">
                              <input
                                value={verificationCode}
                                id="code"
                                placeholder={MvnMessage(SSO_USER_VERIFICATION_CODE_LANG.SSO_USER_VERIFICATION_CODE_PLACEHOLDER)}
                                className="code"
                                type="text"
                                onChange={(e) => {
                                  setVerificationCode(e.target.value);
                                }}
                                onKeyDown={(e) => e.key === "Enter" && verificationCode && verifyBtnRef.current?.click()}
                              />
                            </label>
                          )}
                        </fieldset>
                        <div className="button">
                          {!verificationCodeUuid && (
                            <>
                              <CrButton
                                loading={loginResponse.busy || sendVerificationCodeForDeviceRegistrationResponse.busy}
                                disabled={loginResponse.busy || !emailVal || (!passwordVal && checkIsPasswordConfigured(emailVal))}
                                color="primary"
                                onClick={() => {
                                  const deviceToken = getCookie(DEVICE_KEY);

                                  emailVal &&
                                    loginRequest({
                                      data: {
                                        email: emailRef,
                                        password: passwordRef,
                                        service,
                                        checkPreviousAuthToken: true,
                                        deviceToken,
                                      },
                                    });
                                }}
                              >
                                Sign in
                              </CrButton>
                              <p>
                                <Link to={"/auth/password/find"} target="_blank" rel="noopener noreferrer">
                                  Forgot Password?
                                </Link>
                              </p>
                            </>
                          )}
                          {verificationCodeUuid && (
                            <div style={{ marginBottom: "1em" }}>
                              <CrButton
                                loading={loginResponse.busy || verifyUserVerificationCodeResponse.busy || createMyDeviceResponse.busy}
                                color="primary"
                                elementRef={verifyBtnRef}
                                disabled={!verificationCode || !verificationCodeUuid}
                                onClick={() => {
                                  verifyUserVerificationCodeRequest();
                                }}
                              >
                                Verify
                              </CrButton>
                            </div>
                          )}
                        </div>
                      </div>
                      <strong className="copyright">
                        <div className="privacy-policy">
                          <a href={getPrivacyPolicyUrl(lang)} target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                          </a>{" "}
                        </div>
                        Copyright &copy; 2020-{new Date().getFullYear()}{" "}
                        <a href="https://www.jnpmedi.com" target="_blank" rel="noopener noreferrer">
                          JNPMEDI
                        </a>{" "}
                        all rights reserved.
                      </strong>
                      {/* Dialogs */}
                      <InitPasswordDialog
                        messages={initPasswordDialog?.messages}
                        visibility={initPasswordDialogVisibility}
                        email={emailRef}
                        currentPassword={passwordRef}
                        passwordUpdatedEventHandler={(password) => {
                          const deviceToken = getCookie(DEVICE_KEY);

                          setInitPasswordDialogVisibility(false);
                          setPasswordVal(password);
                          loginRequest({ data: { email: emailRef, password, service, deviceToken } });
                        }}
                        cancelEventHandler={() => setInitPasswordDialogVisibility(false)}
                      />
                      <InitEmailVerifyPasswordDialog
                        key={`${emailRef.current?.value || "none"}`}
                        messages={initEmailVerifyPasswordDialog.messages}
                        visibility={initEmailVerifyPasswordDialogVisibility}
                        email={emailRef}
                        passwordUpdatedEventHandler={(password) => {
                          const deviceToken = getCookie(DEVICE_KEY);

                          setInitEmailVerifyPasswordDialogVisibility(false);
                          setPasswordVal(password);
                          loginRequest({ data: { email: emailRef, password, service, deviceToken } });
                        }}
                        cancelEventHandler={() => setInitEmailVerifyPasswordDialogVisibility(false)}
                        emailVal={emailVal}
                      />
                      <UpdatePasswordDialog
                        messages={updatePasswordDialog?.messages}
                        visibility={updatePasswordDialogVisibility}
                        email={emailVal}
                        currentPassword={passwordVal}
                        updatePasswordDoneEventHandler={(password) => {
                          const deviceToken = getCookie(DEVICE_KEY);

                          setUpdatePasswordDialogVisibility(false);
                          loginRequest({ data: { email: emailVal, password, service, deviceToken } });
                        }}
                        cancelEventHandler={() => {
                          setVerificationCodeUuid("");
                          setUpdatePasswordDialogVisibility(false);
                        }}
                      />
                      <DuplicateLoginDialog
                        messages={duplicateLoginDialog?.messages}
                        visibility={duplicateLoginDialogVisibility}
                        confirmEventHandler={() => {
                          const deviceToken = getCookie(DEVICE_KEY);

                          loginRequest({ data: { email: emailVal, password: passwordVal, service, deviceToken } });
                        }}
                        cancelEventHandler={() => {
                          setVerificationCodeUuid("");
                          setDuplicateLoginDialogVisibility(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </Switch>
  );
}

export function getPrivacyPolicyUrl(lang: LoLang): string {
  const KO_PRIVACY_POLICY_URL = "https://mvn.do/legal/privacy";
  const EN_PRIVACY_POLICY_URL = "https://mvn.do/legal/privacy/en";

  if (lang === LoLang.KO) return KO_PRIVACY_POLICY_URL;

  return EN_PRIVACY_POLICY_URL;
}

export default function AuthLayout() {
  return (
    <AuthLayoutInner
      service={MavenService.MavenCDMS}
      messages={{
        code: {
          initialPasswordNotSet: SSO_AUTH_TOKEN_CODE.SSO_AUTH_TOKEN_CREATE_INVALID_INIT_PASSWORD.code!,
          passwordNotConfigured: SSO_AUTH_TOKEN_CODE.SSO_AUTH_TOKEN_CREATE_INVALID_INIT_PASSWORD_BY_EMAIL_VERIFICATION.code!,
          passwordChangeCycleExceed: SSO_AUTH_TOKEN_CODE.SSO_AUTH_TOKEN_CREATE_INVALID_PASSWORD_EXPIRED.code!,
          previousAuthTokenAlive: SSO_AUTH_TOKEN_CODE.SSO_AUTH_TOKEN_CREATE_INVALID_PREVIOUS_TOKEN_EXIST.code!,
        },
      }}
      initPasswordDialog={{
        messages: {
          dialogTitle: {
            default: MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_PASSWORD_SET),
          },

          label: {
            password: MvnMessage(AUTH_TOKEN_LANG.PASSWORD),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.CONFIRM_PASSWORD),
          },

          description: {
            password: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_VALIDATION),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_NOT_MATCH),
          },

          placeholder: {
            password: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_PLACEHOLDER),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_CONFIRM_PLACEHOLDER),
          },

          response: {
            passwordUpdateError: (error) =>
              MvnMessage(AUTH_TOKEN_LANG.PASSWORD_CHANGE_FAILED, { message: error || "An error has occurred." }),
          },

          errorMessage: {
            password: MvnMessage(AUTH_TOKEN_LANG.CHANGE_PASSWORD_INVALID_SAME_EMAIL),
          },
        },
      }}
      initEmailVerifyPasswordDialog={{
        messages: {
          dialogTitle: {
            default: MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_EMAIL_VERIFY),
            codeSent: MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_EMAIL_SENT),
            enterPassword: MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_ENTER_PASSWORD),
          },

          button: {
            confirm: MvnMessage(COMMON_LANG.CONFIRM),
            cancel: MvnMessage(COMMON_LANG.CANCEL),
            verify: MvnMessage(COMMON_LANG.VERIFY),
            requestVerificationCode: MvnMessage(AUTH_TOKEN_LANG.REQUEST_VERIFICATION_CODE),
          },

          label: {
            email: MvnMessage(AUTH_TOKEN_LANG.EMAIL),
            verificationCode: MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_CODE),
            passwordNew: MvnMessage(AUTH_TOKEN_LANG.PASSWORD),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.CONFIRM_PASSWORD),
          },

          placeholder: {
            email: MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_CODE),
            verificationCode: MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_PLACEHOLDER),
            passwordNew: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_PLACEHOLDER),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_CONFIRM_PLACEHOLDER),
          },

          response: {
            verificationCodeSent: MvnMessage(AUTH_TOKEN_LANG.EMAIL_SEND_SUCCEED),
            verificationCodeSendError: (error) => MvnMessage(AUTH_TOKEN_LANG.EMAIL_SEND_FAILED, { message: error }),
            verificationCodeConfirmed: MvnMessage(AUTH_TOKEN_LANG.INITIAL_SETUP_TITLE_ENTER_PASSWORD),
            verificationCodeTryLimitExceed: MvnMessage(AUTH_TOKEN_LANG.VERIFY_LIMIT_EXCEED),
            verificationCodeNotMatched: MvnMessage(AUTH_TOKEN_LANG.VERIFICATION_NOT_MATCH),
            passwordUpdateError: (error) =>
              MvnMessage(AUTH_TOKEN_LANG.PASSWORD_CHANGE_FAILED, { message: error || "An error has occurred." }),
          },

          alert: {
            passwordValidate: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_VALIDATION),
            passwordNotMatched: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_NOT_MATCH),
          },

          code: {
            verificationCodeTryLimitExceed: AUTH_TOKEN_CODE.VERIFY_CODE_LOCK.code!,
          },
        },
      }}
      updatePasswordDialog={{
        messages: {
          dialogTitle: {
            default: MvnMessage(AUTH_TOKEN_LANG.UPDATE_SETUP_TITLE_PASSWORD_SET),
          },

          label: {
            password: MvnMessage(AUTH_TOKEN_LANG.PASSWORD),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.CONFIRM_PASSWORD),
          },

          description: {
            password: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_VALIDATION),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.PASSWORD_NOT_MATCH),
          },

          placeholder: {
            password: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_PLACEHOLDER),
            passwordConfirm: MvnMessage(AUTH_TOKEN_LANG.NEW_PASSWORD_CONFIRM_PLACEHOLDER),
          },

          response: {
            passwordUpdateError: (error) =>
              MvnMessage(AUTH_TOKEN_LANG.PASSWORD_CHANGE_FAILED, { message: error || "An error has occurred." }),
          },

          errorMessage: {
            password: MvnMessage(AUTH_TOKEN_LANG.CHANGE_PASSWORD_INVALID_SAME_EMAIL),
          },
        },
      }}
      duplicateLoginDialog={{
        messages: {
          dialogText: {
            default: MvnMessage(AUTH_TOKEN_LANG.LOGIN_DUPLICATE_TEXT),
          },
        },
      }}
    />
  );
}
